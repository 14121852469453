import { Controller } from "@hotwired/stimulus"
import { Loader } from "@googlemaps/js-api-loader"

export default class extends Controller {
  static targets = ["map", "locations"];
  static values = { apiKey: String, locations: Array };

  connect() {
    const loader = new Loader({
      apiKey: this.apiKeyValue,
      version: "quarterly",
      libraries: ["maps", "geocoding", "marker", "elevation"]
    })

    loader
      .load()
      .then(this.initMap.bind(this))
      .catch((e) => {
        console.log(e)
        console.log("error loading Google Maps")
      })
  }

  initMap(google) {
    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 5,
      center: { lat: 23.6345, lng: -102.5528 } // Center of Mexico
    });

    this.markers = [];
    this.locationsValue.forEach(location => {
      const marker = new google.maps.Marker({
        position: { lat: location.location.latitude, lng: location.location.longitude },
        map: this.map,
        title: location.displayName.text
      });
      this.markers.push(marker);
    });
  }

  centerMap(event) {
    const lat = parseFloat(event.currentTarget.dataset.locationMapLatitudeParam);
    const lng = parseFloat(event.currentTarget.dataset.locationMapLongitudeParam);
    this.map.setCenter({ lat, lng });
    this.map.setZoom(12);
  }

  get locations() {
    return JSON.parse(this.data.get("locations"));
  }
}