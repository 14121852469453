import { Controller } from '@hotwired/stimulus'
import { Notyf } from 'notyf'

export default class extends Controller {
  initialize() {
    this.notyf = new Notyf()
  }

  connect() {
    document.addEventListener('show_flash', this.notify)
  }

  disconnect() {
    document.removeEventListener('show_flash', this.notify)
  }

  notify = event => {
    this.notyf.open(event.detail)
  }
}