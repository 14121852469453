import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["icon", "content"]

  toggle() {
    if (this.contentTarget.classList.contains("hidden")) {
      this.iconTarget.classList.replace("fa-plus", "fa-minus")
    } else {
      this.iconTarget.classList.replace("fa-minus", "fa-plus")
    }
    this.contentTarget.classList.toggle("hidden")
  }
}
